import {A11y, Navigation} from 'swiper';
import {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Path} from '../../app/const';
import ProfileCard from '../../shared/ProfileCard';
import Button from '../Button';
import 'swiper/css';
import styles from './PeopleSlider.module.scss';
import {selectCandidates, getCandidatesList} from '../../features/MeetSomeone/searchSlice';
import {selectedCandidates} from '../../features/AttendTheEvent/eventsSlice';
import {useAppSelector} from '../../app/hooks';
import {createRoom, getRooms, setRootPath} from '../../features/Chats/chatSlice';
import {useAppDispatch} from '../../app/hooks';
import {useNavigate, useLocation} from 'react-router-dom';
import 'swiper/css/navigation';
import {selectProfileType} from '../../app/profileSlice';
import SlideCounter from '../SlideCounter';
import {useTranslation} from 'react-i18next';
import ProfileCardSkeleton from '../ProfileCardSkeleton';

interface PeopleSliderProps {
  rootPath?: string;
}

export default function PeopleSlider({rootPath}: PeopleSliderProps) {
  const {search, pathname} = useLocation();
  const uuid = search.split('=')[1];
  //const id = new URL(window.location.href).searchParams.get('id');
  //const type = new URL(window.location.href).searchParams.get('type');
  const profile_type = useAppSelector(selectProfileType);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const {candidates, loading} = useAppSelector(selectCandidates);
  const candidatesList = useAppSelector(selectedCandidates);
  const mappedCandidates = profile_type === 'creator' ? candidatesList : candidates;
  const {t} = useTranslation();
  const [type, setType] = useState<string | any>();
  const [id, setId] = useState<string | any>();
  const searchParam = useLocation()?.search;
  const splitSearch = searchParam?.split('?');

  useEffect(() => {
    splitSearch.map((item) => {
      return item.includes('type') && setType(item.split('=')[1]);
    });
    splitSearch.map((item) => {
      return item.includes('id') && setId(item.split('=')[1]);
    });
  }, []);

  const path = `${window.location.pathname}${search}`;

  useEffect(() => {
    profile_type === 'default' &&
      pathname !== '/browse-interests/matching-results' &&
      uuid &&
      dispatch(getCandidatesList(uuid));
  }, [profile_type]);

  //test

  return (
    <div className={styles.root}>
      {profile_type !== 'creator' && loading === 'loading' ? (
        <ProfileCardSkeleton />
      ) : (
        <Swiper className={styles.slider} modules={[A11y, Navigation]} navigation spaceBetween={8}>
          {mappedCandidates &&
            mappedCandidates.length > 0 &&
            mappedCandidates.map((result: any, index: any) => (
              <SwiperSlide key={index} className={styles.slide}>
                {({isActive}) => (
                  <>
                    <ProfileCard className={styles.card} profile_type={profile_type} {...result}>
                      {profile_type !== 'creator' && (
                        <>
                          <Button
                            className='btn'
                            style={{margin: '12px 0px'}}
                            onClick={() => {
                              dispatch(setRootPath(path));
                              dispatch(
                                createRoom({
                                  profile_id: result.id
                                })
                              ).then((res) => {
                                if (res.type && res.type.includes('fulfilled')) {
                                  history(`${Path.Chats}/${res.payload.id}?isFromSearch=true`);
                                  dispatch(getRooms());
                                }
                              });
                            }}
                          >
                            {t('PEOPLESLIDER.start')}
                          </Button>

                          {/* //SEPARATE BUTTON FROM BOTTOM */}
                          <div style={{padding: '5px'}}></div>
                        </>
                      )}
                    </ProfileCard>

                    <SlideCounter length={mappedCandidates.length} index={index} isActive={isActive} />
                  </>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>
  );
}
