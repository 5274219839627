import {useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import {useTranslation} from 'react-i18next';
import {useSearchParams, useParams} from 'react-router-dom';
import {Path} from '../../../app/const';
import PeopleSlider from '../../../shared/PeopleSlider';
import {getListOfIneterestedPeople} from '../../AttendTheEvent/eventsSlice';
import {useAppDispatch} from '../../../app/hooks';

const AttendedInterestedPeople = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const {t} = useTranslation();
  const {eventId} = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    eventId && type && dispatch(getListOfIneterestedPeople({id: eventId, type: type}));
  }, []);

  return (
    <PageWrap
      goBack={`${Path.EventCreatorDetails}/${eventId}`}
      title={`${type === 'interested' ? t('EVENTPEOPLE.interested') : t('EVENTPEOPLE.attneding')} ${t(
        'EVENTPEOPLE.people'
      )}`}
    >
      <PeopleSlider />
    </PageWrap>
  );
};

export default AttendedInterestedPeople;
